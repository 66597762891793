<script lang="ts" setup>
import type { HomepageCardContainer } from './HomepageCardContainer.props'
import emblaCarouselVue from 'embla-carousel-vue'
import { vTrimmedScroll } from '@design-system/directives/trimmedScroll'
import type { DirectionType } from '@design-system/directives/trimmedScroll'
import { vTrimmedTouchScroll } from '@design-system/directives/trimmedTouchScroll'

const props = defineProps<HomepageCardContainer>()
const emit = defineEmits<{
  'scroll-cards': [newIndex: number]
  'last-card-reached': [boolean]
}>()

const [emblaRef] = emblaCarouselVue({ loop: true })
const isLandscape = ref(false)

const updateViewportOrientation = () => {
  isLandscape.value = window.innerWidth > window.innerHeight
}

/*  Scrolling states */
const isScrollingSlides = ref(true) // if false the full page is scrolling, if true the slides are scrolling
const currentRow = ref(0) // handle the current row of the slides
const scrollOutOfView = ref(false) // if true the slides container is scrolling out of view

const homepageScrollContainer = ref<HTMLElement | null>(null)

const currentDirection = ref<'up' | 'down' | null>(null)

const followingContainer = ref<HTMLElement | null>(null)

const isThereContentAfterCards = ref(false)

if (props.editorialCards?.[0]?.listOfLogicWrapper?.length > 0) {
  isThereContentAfterCards.value = true
}

const cardArrays = computed(() => {
  const chunkSize = 4
  const cardArray = chunk(props.brandCards ?? [], chunkSize)
  return cardArray
})
const isAnimationRunning = ref(false) // prevents new scrolls to be triggered while the previous one is still running
let isAnimationRunningTimout: any = null

const ANIMATION_TIMEOUT = 400 // debounce time, increase if the animation is too fast
const FOLLOWING_CONTAINER_THRESHOLD = computed(() => {
  if (isThereContentAfterCards.value) {
    return 100
  }
  return window.innerHeight
})

const editorialModuleTop = props.editorialValuesTop
const isEditorialValues =
  editorialModuleTop?._meta?.schema === 'https://armani.com/values-editorial'
const isLiveStory =
  editorialModuleTop?._meta?.schema ===
  'https://armani.com/editorial-social-wall'

const runAnimation = () => {
  isAnimationRunning.value = true
  clearTimeout(isAnimationRunningTimout)
  isAnimationRunningTimout = setTimeout(() => {
    isAnimationRunning.value = false
  }, ANIMATION_TIMEOUT)
}

const isAScrollableModalVisible = ref(false)
const cookieBannerCenter = 'modal-cookie-banner-center'
const { isDialogOpen } = useDialog(cookieBannerCenter)
watch(isDialogOpen, newVal => {
  isAScrollableModalVisible.value = newVal
})

const handleScroll = (direction: DirectionType) => {
  if (isAScrollableModalVisible.value) return

  currentDirection.value = direction

  if (!isScrollingSlides.value) {
    handleFreeScroll(direction)
    return
  }

  window.scrollTo(0, 0)

  if (direction === 'down') {
    handleScrollDown()
  } else if (direction === 'up') {
    handleScrollUp()
  }
}

const handleFreeScroll = (direction: DirectionType) => {
  if (direction === 'down' || !isLandscape.value) return

  if (followingContainer.value) {
    if (
      Math.abs(followingContainer.value.getBoundingClientRect().top) <=
      FOLLOWING_CONTAINER_THRESHOLD.value
    ) {
      window.dispatchEvent(new Event('header-home-expand'))
      scrollOutOfView.value = false
      isScrollingSlides.value = true

      currentRow.value = props.editorialValuesTop
        ? cardArrays.value.length + 1
        : cardArrays.value.length
    }
  }
}

const handleScrollDown = () => {
  const cardsLength = props.editorialValuesTop
    ? cardArrays.value.length + 1
    : cardArrays.value.length

  // AKA your are good to go
  if (currentRow.value < cardsLength - 1) {
    // AKA there is the initial video and the header should expand
    if (props.editorialValuesTop && currentRow.value === 0) {
      currentRow.value++
      window.dispatchEvent(new Event('header-home-expand'))
    } else {
      // AKA you are good to go as is
      currentRow.value++
    }
    runAnimation()
    // AKA you are at the last card
  } else if (currentRow.value === cardsLength - 1) {
    currentRow.value++
    scrollOutOfView.value = true
    runAnimation()
    if (isThereContentAfterCards.value) {
      // AKA there is editorial content after the cards
      window.dispatchEvent(new Event('header-home-reduce'))
    } else {
      // AKA there is no editorial content after the cards and the header should expand to cover the whole page
      window.dispatchEvent(new Event('header-home-expand-to-footer'))
    }
    emit('last-card-reached', true)
  } else if (currentRow.value === cardsLength) {
    isScrollingSlides.value = false
  }
}

const handleScrollUp = () => {
  const cardsLength = props.editorialValuesTop
    ? cardArrays.value.length + 1
    : cardArrays.value.length

  if (
    currentRow.value === cardsLength - 1 ||
    currentRow.value === cardsLength
  ) {
    scrollOutOfView.value = false
    isScrollingSlides.value = true
    currentRow.value--
    runAnimation()
    emit('last-card-reached', false)
    window.dispatchEvent(new Event('header-home-expand'))
  } else if (currentRow.value > 0) {
    if (props.editorialValuesTop && currentRow.value === 1) {
      currentRow.value--
      window.dispatchEvent(new Event('header-home-reduce'))
    } else {
      currentRow.value--
    }
    runAnimation()
  }
}

/**
 * Scrolls to the top of the page and resets the current row to 0
 * it's used at the click of the "back to top" button
 */
const backToTop = () => {
  window.scrollTo(0, 0)

  for (let i = 0; i <= currentRow.value; i++) {
    setTimeout(() => {
      handleScroll('up')
    }, 150 * i)
  }
}

defineExpose({ handleScroll, backToTop })

const handleFocusIn = (index: number) => {
  currentRow.value = index
}

onMounted(() => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 10)
  backToTop()

  followingContainer.value = document.querySelector(
    '[data-testid="other-banner"]'
  )
  if (!followingContainer.value)
    followingContainer.value = document.querySelector('footer')

  if (followingContainer.value) {
    followingContainer.value.classList.add('relative')
    followingContainer.value.classList.add('z-[1]')
  }

  updateViewportOrientation()
  window.addEventListener('resize', updateViewportOrientation)
  window.addEventListener('deviceorientation', updateViewportOrientation)

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      handleScroll('down')
      window.scrollTo(0, 0)
    } else if (event.key === 'ArrowUp') {
      handleScroll('up')
    }
  }

  window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateViewportOrientation)
  window.removeEventListener('deviceorientation', updateViewportOrientation)
})
</script>

<template>
  <div
    class="hp-card-container-landscape"
    :class="{
      'hp-has-editorial-values-top': editorialValuesTop,
      'hp-has-editorial-values-top--scrolled': currentRow >= 1,
    }"
  >
    <div
      v-if="editorialModuleTop"
      class="hp-card-container-landscape-top"
      :class="{
        'hp-card-container-landscape-top--scrolled': currentRow >= 1,
      }"
    >
      <EditorialValues
        v-if="isEditorialValues"
        v-bind="editorialModuleTop"
        :is-home="true"
        class="*:!aspect-[none] *:!h-lvh"
      />
      <EditorialSocialWall v-if="isLiveStory" v-bind="editorialModuleTop" />
    </div>
    <div
      ref="homepageScrollContainer"
      v-trimmed-touch-scroll="{
        container: 'body',
        onScroll: dir => handleScroll(dir),
        freeScroll:
          !isLandscape || !isScrollingSlides || isAScrollableModalVisible,
      }"
      v-trimmed-scroll="{
        container: 'body',
        onScroll: dir => handleScroll(dir),
        freeScroll: !isScrollingSlides || isAScrollableModalVisible,
      }"
      class="hp-scroll-container relative overflow-y-scroll"
      :class="{
        'hp-scroll-container--scrolled':
          scrollOutOfView && isThereContentAfterCards,
        'hp-scroll-container--scrolled--footer':
          scrollOutOfView && !isThereContentAfterCards,
      }"
    >
      <div
        v-for="(cards, index) in cardArrays"
        :key="'homepage-cards-row' + index"
        class="hp-scroll-row absolute inset-0"
        :class="{
          'hp-scroll-row--scrolled':
            currentRow >= (editorialValuesTop ? index + 1 : index) ||
            index === 0,
        }"
        :data-index="editorialValuesTop ? index + 1 : index"
        @focus="handleFocusIn(index)"
      >
        <div class="grid grid-cols-4">
          <div v-for="(card, i) in cards" :key="i" class="bg-neutral-white">
            <HomepageBrandCardWrapper
              :card="card"
              :fetch-priority-image="false"
              :is-portrait-card="false"
              :index="index"
              :data-testid="`home-brand-card-${card.brand}`"
            />
          </div>
        </div>
      </div>
    </div>
    <template v-for="logicWrapper in editorialCards?.[0]?.listOfLogicWrapper">
      <template
        v-for="(content, index) in logicWrapper?.contents"
        :key="`logic-wrapper-${index}`"
      >
        <EditorialValues
          v-bind="content.content"
          :is-home="true"
          :data-testid="index === 0 && 'other-banner'"
          class="*:!aspect-[none] *:!h-lvh"
        />
      </template>
    </template>
  </div>
  <!-- MOBILE -->
  <div class="hp-card-container-portrait">
    <div ref="emblaRef" class="embla">
      <div class="embla__container" grab-cursor>
        <div v-if="editorialModuleTop" class="embla__slide overflow-hidden">
          <EditorialValues
            v-if="isEditorialValues"
            v-bind="editorialModuleTop"
            :is-home="true"
            class="editorial-values-homepage--wrapper"
          />
          <div v-if="isLiveStory">
            <div class="overflow-hidden [height:var(--brand-image-height)]">
              <EditorialSocialWall
                v-bind="editorialModuleTop"
                :id="editorialModuleTop?.mobileId || editorialModuleTop.id"
              />
            </div>
            <div
              class="homepage-logo-banner flex items-center justify-center p-2 text-center"
            >
              {{ editorialModuleTop?.title }}
            </div>
          </div>
        </div>

        <div
          v-for="(brandCard, index) in brandCards"
          :key="brandCard.brand + '-homepage-card'"
          class="embla__slide overflow-hidden"
        >
          <HomepageBrandCardWrapper
            :card="brandCard"
            :is-portrait-card="true"
            :fetch-priority-image="index === 0"
            :index="index"
          />
        </div>
        <template
          v-for="logicWrapper in editorialCards?.[0]?.listOfLogicWrapper"
          :key="logicWrapper.contents"
        >
          <div
            v-for="(content, indexContent) in logicWrapper?.contents"
            :key="`logic-wrapper-${indexContent}`"
            class="embla__slide overflow-hidden"
          >
            <EditorialValues
              v-bind="content.content"
              :is-home="true"
              class="editorial-values-homepage--wrapper"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:root {
  --motion-timing-function: cubic-brazier(0.37, 0, 0.63, 1);
}

.hp-card-container-landscape-top {
  transition:
    transform 0.5s ease-in-out,
    filter 0.5s ease-in-out,
    opacity 0.1s ease-in-out 0.1ms;

  position: absolute;
  inset: 0;

  &--scrolled {
    @apply transform-gpu;
    transition:
      transform 0.4s ease-in-out,
      filter 0.4s ease-in-out,
      opacity 0.1s ease-in-out 0.4s;

    filter: blur(10px);
    transform: translateY(-20vh);
    opacity: 0;
  }
}

.hp-has-editorial-values-top {
  margin-top: calc(100vh - 72px);
  transition: margin-top 0.6s cubic-bezier(0.42, 0, 0.58, 1);
  // transition-delay: 0.2s;

  &--scrolled {
    transition: margin-top 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    transition-delay: 0s;
    margin-top: 0;
  }
}

.hp-scroll-container {
  height: calc(100vh - var(--header-container-height));
  overflow: hidden;
  margin-bottom: 0px;
  transition:
    margin-bottom 0.8s,
    all 0.6s; // longer cause the next slide is full page
  transition-timing-function: var(--motion-timing-function);
  z-index: 0;

  &--scrolled {
    @apply transform-gpu;
    margin-bottom: calc(-1 * (100vh - var(--header-container-height) + 108px));
    transform: scale3d(0.98, 0.98, 1);
  }

  &--scrolled--footer {
    @apply transform-gpu;
    margin-top: calc(-1 * (100vh - var(--header-container-height)));
  }
}

.hp-scroll-row {
  margin-top: calc(100vh - var(--header-container-height));
  transition: margin-top 0.6s;
  transition-timing-function: var(--motion-timing-function);

  &--scrolled {
    margin-top: 0;
  }
}

.hp-card-container-portrait {
  display: block;
}

.hp-card-container-landscape {
  display: none;
}

@media (orientation: landscape) {
  .hp-card-container-portrait {
    display: none;
  }

  .hp-card-container-landscape {
    display: block;
  }
}

.embla {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__container {
  display: flex;
  transform: translate3d(calc(10vw + 8px), 0px, 0px);
}

.embla__slide {
  flex: 0 0 calc(((100vw)) - 16px - 20vw);
  margin: 0 8px;
}
</style>
